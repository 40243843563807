<template>
  <DialogRoot
    :open="isOpen || isStorybook"
    :modal="!isStorybook"
    @update:open="closeDialog"
  >
    <DialogPortal :disabled="isStorybook">
      <div class="buttondown-dialog-root">
        <DialogOverlay
          class="dialog--overlay fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm"
        />
        <DialogContent
          ref="contentRef"
          :aria-describedby="undefined"
          class="dialog--content"
          :class="[
            `fixed left-1/2 -translate-x-1/2`,
            isMobile
              ? `rounded-t-lg bottom-0`
              : `rounded-lg top-1/2 -translate-y-1/2`,
            `bg-white shadow-xl`,
            `w-full overflow-y-auto`,
            widthClass,
          ]"
        >
          <slot v-if="$slots.header" name="header"></slot>
          <div v-else-if="title !== null" class="p-4">
            <DialogTitle class="text-base font-bold">{{ title }}</DialogTitle>
          </div>

          <div
            :class="[
              `px-4`,
              !($slots.header || title !== null) && `pt-4`,
              !slots.actions && `pb-4`,
            ]"
          >
            <slot></slot>
          </div>

          <div
            v-if="slots.actions"
            class="dialog--actions"
            :class="[
              `sticky z-10 bottom-0 flex justify-between gap-4 border-t p-4`,
              isMobile && `flex-col`,
              showActionsBorder
                ? `border-gray-300 bg-white`
                : `border-transparent`,
            ]"
          >
            <slot name="actions"></slot>
          </div>
        </DialogContent>
      </div>
    </DialogPortal>
  </DialogRoot>
</template>

<script lang="ts">
/** @deprecated */
export const DIALOG_CLASS = "buttondown-dialog-root";
</script>

<script setup lang="ts">
import {
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogRoot,
  DialogTitle,
} from "radix-vue";
import { computed, onWatcherCleanup, ref, watch } from "vue";

import { useMediaQuery } from "@/lib/media_query";

const emit = defineEmits<{
  close: [];
}>();

const slots = defineSlots<{
  default(props: {}): void;
  header?(props: {}): void;
  actions?(props: {}): void;
}>();

const props = withDefaults(
  defineProps<{
    title: string | null;
    variant?: "wide" | "very-wide" | "super-wide" | "narrow";
  }>(),
  {
    variant: "wide",
  }
);

const contentRef = ref<any>();

const showActionsBorder = ref(false);

const isMobile = useMediaQuery("(width < 768px)");
const isOpen = ref(true);

// This is already enforced with the types
// eslint-disable-next-line vue/return-in-computed-property
const widthClass = computed((): string => {
  switch (props.variant) {
    case "wide": {
      return `max-w-xl`;
    }
    case "narrow": {
      return `md:max-w-md`;
    }
    case "very-wide": {
      return `max-w-3xl md:min-w-[20rem]`;
    }
    case "super-wide": {
      return `max-w-5xl md:min-w-[20rem]`;
    }
  }
});

const closeDialog = () => {
  isOpen.value = false;
  setTimeout(() => emit("close"), 250);
};

watch(
  [() => contentRef.value?.$el, () => !!slots.actions],
  ([contentEl, hasActions]: [HTMLElement | undefined, boolean]) => {
    if (!contentEl || !hasActions) {
      return;
    }

    const update = () => {
      const clientHeight = contentEl.clientHeight;
      const scrollHeight = contentEl.scrollHeight;
      const scrollTop = contentEl.scrollTop;

      const isScrollable = scrollHeight > clientHeight;
      const isAtBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 2;

      showActionsBorder.value = isScrollable && !isAtBottom;
    };

    const observer = new ResizeObserver(update);

    observer.observe(contentEl);
    contentEl.addEventListener("scroll", update, { passive: true });

    onWatcherCleanup(() => {
      observer.disconnect();
      contentEl.removeEventListener("scroll", update);
    });
  },
  { immediate: true }
);

const isStorybook = computed(() => window.location.pathname.includes("memex"));
</script>

<style lang="scss" scoped>
.buttondown-dialog-root {
  z-index: 20;

  :deep(.dialog--overlay) {
    &[data-state="open"] {
      animation: overlay-fade-in 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: overlay-fade-out 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
  }

  :deep(.dialog--content) {
    // Max height is 820px, or screen height - 48px * 2 (top and bottom),
    // whichever is the lowest, a little bit awkward to describe in Tailwind.
    max-height: min(820px, calc(100% - (48px * 2)));

    &[data-state="open"] {
      animation: content-scale-in 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: content-scale-out 250ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    @media (width < 768px) {
      // We're a bit cramped for space wrt mobile, so let's bump the max-height
      // a little bit (65px = header height + 12px)
      max-height: min(820px, calc(100% - 65px));

      &[data-state="open"] {
        animation: content-slide-in 250ms cubic-bezier(0.16, 1, 0.3, 1);
      }
      &[data-state="closed"] {
        animation: content-slide-out 250ms cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
  }

  // Awkward to use Tailwind for a one-off, this is just `transition-colors` but
  // it only applies to `border-color`. I've noticed that if `background-color`
  // was set all the time it would cut off the outlines of inputs.
  :deep(.dialog--actions) {
    transition: border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

@keyframes overlay-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes overlay-fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes content-scale-in {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes content-scale-out {
  from {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.96);
  }
}

@keyframes content-slide-in {
  from {
    opacity: 0;
    transform: translate(-50%, 16px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@keyframes content-slide-out {
  from {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  to {
    opacity: 0;
    transform: translate(-50%, 16px);
  }
}
</style>
